import React, { Component } from "react";
import Rte from "../atoms/rte";
import Image from "../molecules/image";
import ButtonGroup from "../molecules/button-group";

import "../../scss/organisms/_section.scss";

const helpers = require("../../helpers");

class sectionFold extends Component {
  componentDidMount() {
    helpers.useIntersect("section.fold", "scrolled");
  }

  handleJump() {
    const fold = document.querySelector("section.fold");

    fold &&
      window.scrollTo({
        top: fold.clientHeight - 80,
        behavior: "smooth",
      });
  }

  constructor(props) {
    super(props);

    this.key = this.props.pageData.key;
    this.pageTitle = this.props.pageData.pageTitle;
    this.description = this.props.pageData.description;
    this.callToActions = this.props.pageData.callToActions;
    this.bgImage = this.props.pageData.pageHeaderImage?.fluid;
    this.bgColor = ["fold", this.props.bgColor].join(" ");
    this.showScrollTo = this.props.showScrollTo;
  }

  render() {
    return (
      <section className={this.bgColor} key={this.key}>
        <div className="container-fluid h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 col-lg-7 col-xl-6 col-xxl-4 ml-auto">
              <h1 className="mb-5">{this.pageTitle}</h1>
              <div className="rte mb-5">
                <Rte content={this.description} />
              </div>
              {this.callToActions && (
                <ButtonGroup buttons={this.callToActions} />
              )}
            </div>
            <div className="col-12 col-lg-5 col-xl-5 col-xxl-6 ml-auto col-fluid">
              <div className="bg-image">
                {this.bgImage ? <Image fluid={this.bgImage} loading="eager" /> : ""}
              </div>
              {this.showScrollTo && (
                <a className="scroll-to" onClick={() => this.handleJump()}>
                  <i className="icon">&darr;</i>
                  <span className="sr-only">scroll down</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default sectionFold;
